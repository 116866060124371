const TelegramIcon = () => {
    return (
        <svg width="17" height="23" viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_2_869" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="4" width="17" height="17">
                <path d="M16.23 4.39H0.22998V20.39H16.23V4.39Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_2_869)">
                <path d="M11.4418 10.058L7.38453 13.3743L7.1936 13.5307C7.15167 13.564 7.12427 13.6122 7.11727 13.6653L7.07453 14.018L6.95727 15.0089C6.95833 15.021 6.9546 15.0329 6.9468 15.0423C6.93907 15.0515 6.92793 15.0574 6.91587 15.0585C6.9038 15.0595 6.89183 15.0558 6.88255 15.0481C6.87325 15.0403 6.86742 15.0291 6.86634 15.0171L6.53634 14.0262L6.19543 13.0125C6.18089 12.9671 6.18275 12.9181 6.20069 12.8739C6.21863 12.8297 6.25151 12.7931 6.29361 12.7707L10.5154 10.3507L11.31 9.89619C11.4254 9.82892 11.5436 9.97437 11.4418 10.058Z" fill="white"/>
                <path d="M14.0482 4.39H2.4118C1.83315 4.39 1.27819 4.61987 0.86902 5.02904C0.45985 5.43821 0.22998 5.99317 0.22998 6.57182V18.2082C0.22998 18.7869 0.45985 19.3418 0.86902 19.7509C1.27819 20.1601 1.83315 20.39 2.4118 20.39H14.0482C14.6268 20.39 15.1818 20.1601 15.5909 19.7509C16.0001 19.3418 16.23 18.7869 16.23 18.2082V6.57182C16.23 5.99317 16.0001 5.43821 15.5909 5.02904C15.1818 4.61987 14.6268 4.39 14.0482 4.39ZM13.22 9.02091L11.6582 15.9218C11.5845 16.2455 11.1736 16.3918 10.8854 16.1945L8.52725 14.5945L7.32631 15.73C7.27738 15.7759 7.21791 15.8093 7.15318 15.827C7.08845 15.8448 7.02031 15.8465 6.95471 15.832C6.88918 15.8175 6.82815 15.7872 6.77694 15.7437C6.72573 15.7003 6.68589 15.6451 6.66089 15.5827L6.22725 14.31L5.79362 13.0373L3.46907 12.4009C3.40408 12.3867 3.34559 12.3515 3.30271 12.3006C3.25983 12.2497 3.23495 12.1861 3.23194 12.1197C3.22893 12.0532 3.24797 11.9876 3.28608 11.9331C3.3242 11.8786 3.37926 11.8382 3.44271 11.8182L12.4227 8.58273L12.5882 8.52363C12.9363 8.40182 13.2963 8.68363 13.22 9.02091Z" fill="white"/>
            </g>
        </svg>
    )
}

const InstagramIcon = () => {
    return (
        <svg width="17" height="23" viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_2_880" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="4" width="17" height="17">
                <path d="M16.23 4.39H0.22998V20.39H16.23V4.39Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_2_880)">
                <path d="M8.23241 8.28194C5.96374 8.28194 4.12708 10.1206 4.12708 12.3873C4.12708 14.6559 5.96574 16.4926 8.23241 16.4926C10.5011 16.4926 12.3377 14.6539 12.3377 12.3873C12.3377 10.1186 10.4991 8.28194 8.23241 8.28194ZM8.23241 15.0519C6.75974 15.0519 5.56774 13.8593 5.56774 12.3873C5.56774 10.9153 6.76041 9.7226 8.23241 9.7226C9.70441 9.7226 10.8971 10.9153 10.8971 12.3873C10.8977 13.8593 9.70508 15.0519 8.23241 15.0519Z" fill="white"/>
                <path d="M11.5286 4.44091C10.0566 4.37225 6.41061 4.37558 4.93728 4.44091C3.64261 4.50158 2.50061 4.81425 1.57995 5.73491C0.0412822 7.27358 0.237949 9.34691 0.237949 12.3876C0.237949 15.4996 0.0646156 17.5249 1.57995 19.0403C3.12461 20.5843 5.22795 20.3823 8.23263 20.3823C11.3153 20.3823 12.3793 20.3843 13.4693 19.9623C14.9513 19.3869 16.07 18.0623 16.1793 15.6829C16.2486 14.2103 16.2446 10.5649 16.1793 9.09158C16.0473 6.28291 14.54 4.57958 11.5286 4.44091ZM13.8586 18.0223C12.85 19.0309 11.4506 18.9409 8.21329 18.9409C4.87995 18.9409 3.54328 18.9903 2.56795 18.0123C1.44461 16.8943 1.64795 15.0989 1.64795 12.3769C1.64795 8.69358 1.26995 6.04091 4.96661 5.85158C5.81595 5.82158 6.06595 5.81158 8.20396 5.81158L8.23396 5.83158C11.7866 5.83158 14.574 5.45958 14.7413 9.15558C14.7793 9.99891 14.788 10.2522 14.788 12.3869C14.7873 15.6816 14.85 17.0263 13.8586 18.0223Z" fill="white"/>
                <path d="M12.5007 9.07951C13.0305 9.07951 13.46 8.65 13.46 8.12018C13.46 7.59035 13.0305 7.16084 12.5007 7.16084C11.9709 7.16084 11.5413 7.59035 11.5413 8.12018C11.5413 8.65 11.9709 9.07951 12.5007 9.07951Z" fill="white"/>
            </g>
        </svg>
    )
}

const UserIcon = () => {
    return (
        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 12C7.67188 12 5 9.32812 5 6C5 2.71875 7.67188 0 11 0C14.2812 0 17 2.71875 17 6C17 9.32812 14.2812 12 11 12ZM15.1719 13.5C18.6406 13.5 21.5 16.3594 21.5 19.8281V21.75C21.5 23.0156 20.4688 24 19.25 24H2.75C1.48438 24 0.5 23.0156 0.5 21.75V19.8281C0.5 16.3594 3.3125 13.5 6.78125 13.5H7.57812C8.60938 14.0156 9.78125 14.25 11 14.25C12.2188 14.25 13.3438 14.0156 14.375 13.5H15.1719Z" fill="#22B3C1"/>
        </svg>
    )
}

const AreaIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.125 7.5H7.82812C8.53125 3.32812 10.125 0.375 12 0.375C13.8281 0.375 15.4688 3.32812 16.125 7.5ZM7.5 12C7.5 10.9688 7.54688 9.98438 7.64062 9H16.3125C16.4062 9.98438 16.4531 10.9688 16.4531 12C16.4531 13.0781 16.4062 14.0625 16.3125 15H7.64062C7.54688 14.0625 7.5 13.0781 7.5 12ZM22.6875 7.5H17.625C17.2031 4.875 16.4531 2.48438 15.2812 0.890625C18.6562 1.875 21.375 4.35938 22.6875 7.5ZM8.67188 0.890625C7.5 2.48438 6.75 4.875 6.32812 7.5H1.26562C2.57812 4.35938 5.29688 1.875 8.67188 0.890625ZM23.2031 9C23.4375 9.98438 23.5781 10.9688 23.5781 12C23.5781 13.0781 23.4375 14.0625 23.2031 15H17.8125C17.9062 14.0156 18 13.0312 18 12C18 11.0156 17.9062 9.98438 17.8125 9H23.2031ZM6 12C6 13.0312 6.04688 14.0156 6.14062 15H0.75C0.515625 14.0625 0.375 13.0781 0.375 12C0.375 10.9688 0.515625 9.98438 0.75 9H6.14062C6.04688 9.98438 6 11.0156 6 12ZM7.82812 16.5H16.125C15.4688 20.7188 13.8281 23.625 12 23.625C10.125 23.625 8.53125 20.7188 7.82812 16.5ZM15.2812 23.1562C16.4531 21.5625 17.25 19.1719 17.625 16.5H22.6875C21.375 19.6875 18.6562 22.1719 15.2812 23.1562ZM1.26562 16.5H6.32812C6.75 19.1719 7.5 21.5625 8.67188 23.1562C5.29688 22.1719 2.57812 19.6875 1.26562 16.5Z" fill="#22B3C1"/>
        </svg>
    )
}

const HomeIcon = () => {
    return (
        <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.625 5.98438C13.7188 5.89062 13.8594 5.84375 14 5.84375C14.0938 5.84375 14.2344 5.89062 14.3281 5.98438L23 13.0625V20.75C23 21.1719 22.625 21.5 22.25 21.5H16.9531C16.5781 21.5 16.2031 21.1719 16.2031 20.75V16.25C16.2031 15.875 15.875 15.5 15.4531 15.5H12.4531C12.0781 15.5 11.7031 15.875 11.7031 16.25V20.75C11.7031 21.1719 11.375 21.5 11 21.5H5.75C5.32812 21.5 5 21.1719 5 20.75V13.1094L13.625 5.98438ZM27.2656 10.8125C27.4062 10.9062 27.5 11.0938 27.5 11.2344C27.5 11.375 27.4531 11.5156 27.3594 11.6094L26.1406 13.0625C26.0469 13.2031 25.9062 13.25 25.7188 13.25C25.5781 13.25 25.4375 13.2031 25.3438 13.1094L14.3281 4.0625C14.2344 3.96875 14.0938 3.92188 14 3.92188C13.8594 3.92188 13.7188 3.96875 13.625 4.0625L2.60938 13.1094C2.51562 13.2031 2.375 13.25 2.23438 13.25C2.04688 13.25 1.90625 13.2031 1.8125 13.0625L0.59375 11.6094C0.546875 11.5156 0.453125 11.375 0.453125 11.2344C0.453125 11.0938 0.546875 10.9062 0.6875 10.8125L12.5469 1.01562C12.9219 0.734375 13.4375 0.546875 14 0.546875C14.5156 0.546875 15.0312 0.734375 15.4062 1.01562L19.625 4.48438V1.10938C19.625 0.78125 19.8594 0.546875 20.1875 0.546875H22.8125C23.0938 0.546875 23.375 0.78125 23.375 1.10938V7.57812L27.2656 10.8125Z" fill="#22B3C1"/>
        </svg>
    )
}

const PhoneIcon = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.2578 1.23633C20.6875 1.35352 21 1.70508 21 2.13477C21 12.1738 12.875 20.2598 2.875 20.2598C2.40625 20.2598 2.05469 19.9863 1.9375 19.5566L1 15.4941C0.921875 15.0645 1.11719 14.5957 1.54688 14.4004L5.92188 12.5254C6.3125 12.3691 6.74219 12.4863 7.01562 12.7988L8.96875 15.1816C12.0156 13.7363 14.4766 11.2363 15.8828 8.26758L13.5 6.31445C13.1875 6.04102 13.0703 5.61133 13.2266 5.2207L15.1016 0.845703C15.2969 0.416016 15.7656 0.181641 16.1953 0.298828L20.2578 1.23633Z" fill="#22B3C1"/>
        </svg>
    )
}

const MailIcon = () => {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6094 5.7207C19.7656 5.60352 20 5.7207 20 5.91602V13.8848C20 14.9395 19.1406 15.7598 18.125 15.7598H1.875C0.820312 15.7598 0 14.9395 0 13.8848V5.91602C0 5.7207 0.195312 5.60352 0.351562 5.7207C1.25 6.42383 2.38281 7.2832 6.36719 10.1738C7.1875 10.7598 8.59375 12.0488 10 12.0488C11.3672 12.0488 12.8125 10.7598 13.5938 10.1738C17.5781 7.2832 18.7109 6.42383 19.6094 5.7207ZM10 10.7598C9.0625 10.7988 7.77344 9.62695 7.10938 9.1582C1.91406 5.4082 1.52344 5.05664 0.351562 4.11914C0.117188 3.96289 0 3.68945 0 3.37695V2.63477C0 1.61914 0.820312 0.759766 1.875 0.759766H18.125C19.1406 0.759766 20 1.61914 20 2.63477V3.37695C20 3.68945 19.8438 3.96289 19.6094 4.11914C18.4375 5.05664 18.0469 5.4082 12.8516 9.1582C12.1875 9.62695 10.8984 10.7988 10 10.7598Z" fill="#22B3C1"/>
        </svg>
    )
}

const LocationIcon = () => {
    return (
        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.21875 19.8691C1.51562 11.666 0.5 10.8066 0.5 7.75977C0.5 3.61914 3.82031 0.259766 8 0.259766C12.1406 0.259766 15.5 3.61914 15.5 7.75977C15.5 10.8066 14.4453 11.666 8.74219 19.8691C8.39062 20.416 7.57031 20.416 7.21875 19.8691Z" fill="#22B3C1"/>
        </svg>
    )
}

const CardIcon1 = () => {
    return (
        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.53235 5.70752C2.47766 5.70752 1.65735 4.88721 1.65735 3.83252C1.65735 2.80713 2.47766 1.95752 3.53235 1.95752C4.55774 1.95752 5.40735 2.80713 5.40735 3.83252C5.40735 4.88721 4.55774 5.70752 3.53235 5.70752ZM16.6573 5.70752C15.6027 5.70752 14.7823 4.88721 14.7823 3.83252C14.7823 2.80713 15.6027 1.95752 16.6573 1.95752C17.6827 1.95752 18.5323 2.80713 18.5323 3.83252C18.5323 4.88721 17.6827 5.70752 16.6573 5.70752ZM17.5948 6.64502C18.6202 6.64502 19.4698 7.49463 19.4698 8.52002V9.45752C19.4698 9.98486 19.0304 10.395 18.5323 10.395H16.5988C16.3937 9.01807 15.5734 7.84619 14.3722 7.20166C14.7238 6.87939 15.1925 6.64502 15.7198 6.64502H17.5948ZM10.0948 6.64502C8.27844 6.64502 6.8136 5.18018 6.8136 3.36377C6.8136 1.57666 8.27844 0.0825195 10.0948 0.0825195C11.882 0.0825195 13.3761 1.57666 13.3761 3.36377C13.3761 5.18018 11.882 6.64502 10.0948 6.64502ZM12.3214 7.58252C14.1964 7.58252 15.7198 9.10596 15.7198 10.981V11.8013C15.7198 12.5923 15.0753 13.2075 14.3136 13.2075H5.8761C5.08508 13.2075 4.46985 12.5923 4.46985 11.8013V10.981C4.46985 9.10596 5.96399 7.58252 7.83899 7.58252H8.07336C8.6886 7.87549 9.36243 8.05127 10.0948 8.05127C10.798 8.05127 11.4718 7.87549 12.087 7.58252H12.3214ZM5.78821 7.20166C4.58704 7.84619 3.76672 9.01807 3.56165 10.395H1.65735C1.13 10.395 0.719849 9.98486 0.719849 9.45752V8.52002C0.719849 7.49463 1.54016 6.64502 2.59485 6.64502H4.46985C4.9679 6.64502 5.43665 6.87939 5.78821 7.20166Z" fill="#AFAFAF"/>
        </svg>
    )
}

const CardIcon2 = () => {
    return (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3527 6.63721C14.1183 6.82861 14.7198 7.51221 14.7198 8.33252V9.64502C14.7198 10.3013 14.3644 10.8755 13.8448 11.1763V12.27C13.8448 12.7622 13.4347 13.145 12.9698 13.145H12.0948C11.6027 13.145 11.2198 12.7622 11.2198 12.27V11.395H4.21985V12.27C4.21985 12.7622 3.80969 13.145 3.34485 13.145H2.46985C1.97766 13.145 1.59485 12.7622 1.59485 12.27V11.1763C1.04797 10.8755 0.719849 10.3013 0.719849 9.64502V8.33252C0.719849 7.51221 1.29407 6.82861 2.08704 6.63721L2.6886 4.31299C2.93469 3.35596 3.80969 2.64502 4.76672 2.64502H5.09485V1.77002C5.09485 1.30518 5.47766 0.89502 5.96985 0.89502H9.46985C9.93469 0.89502 10.3448 1.30518 10.3448 1.77002V2.64502H10.6456C11.6027 2.64502 12.4777 3.35596 12.7511 4.31299L13.3527 6.63721ZM3.34485 9.64502C3.80969 9.64502 4.21985 9.26221 4.21985 8.77002C4.21985 8.30518 3.80969 7.89502 3.34485 7.89502C2.85266 7.89502 2.46985 8.30518 2.46985 8.77002C2.46985 9.26221 2.85266 9.64502 3.34485 9.64502ZM3.89172 6.58252H11.5206L11.0558 4.75049C11.0011 4.55908 10.7823 4.39502 10.6456 4.39502H4.76672C4.63 4.39502 4.41125 4.55908 4.35657 4.77783L3.89172 6.58252ZM12.0948 9.64502C12.5597 9.64502 12.9698 9.26221 12.9698 8.77002C12.9698 8.30518 12.5597 7.89502 12.0948 7.89502C11.6027 7.89502 11.2198 8.30518 11.2198 8.77002C11.2198 9.26221 11.6027 9.64502 12.0948 9.64502Z" fill="#AFAFAF"/>
        </svg>
    )
}

const CardIcon3 = () => {
    return (
        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.8448 5.77002C14.8019 5.77002 16.4698 6.56299 16.4698 7.52002C16.4698 8.50439 14.8019 9.27002 13.8448 9.27002H10.7003L7.82922 14.3013C7.74719 14.438 7.61047 14.52 7.44641 14.52H5.66907C5.36829 14.52 5.14954 14.2466 5.23157 13.9731L6.57141 9.27002H3.78235L2.57922 10.856C2.49719 10.9653 2.38782 11.02 2.2511 11.02H1.15735C0.856567 11.02 0.637817 10.7739 0.719849 10.5005L1.59485 7.52002L0.719849 4.56689C0.637817 4.29346 0.856567 4.02002 1.15735 4.02002H2.2511C2.38782 4.02002 2.49719 4.10205 2.57922 4.21143L3.78235 5.77002H6.57141L5.23157 1.09424C5.14954 0.820801 5.36829 0.52002 5.66907 0.52002H7.44641C7.61047 0.52002 7.74719 0.629395 7.82922 0.766113L10.7003 5.77002H13.8448Z" fill="#AFAFAF"/>
        </svg>
    )
}

const CardIcon4 = () => {
    return (
        <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6417 13.145C12.8058 13.145 12.9698 13.3091 12.9698 13.4731V14.02H0.719849V13.4731C0.719849 13.3091 0.856567 13.145 1.04797 13.145H1.59485V0.67627C1.59485 0.320801 1.86829 0.0200195 2.2511 0.0200195H11.4386C11.7941 0.0200195 12.0948 0.320801 12.0948 0.67627V13.145H12.6417ZM4.21985 2.09814V3.19189C4.21985 3.3833 4.35657 3.52002 4.54797 3.52002H5.64172C5.80579 3.52002 5.96985 3.3833 5.96985 3.19189V2.09814C5.96985 1.93408 5.80579 1.77002 5.64172 1.77002H4.54797C4.35657 1.77002 4.21985 1.93408 4.21985 2.09814ZM4.21985 4.72314V5.81689C4.21985 6.0083 4.35657 6.14502 4.54797 6.14502H5.64172C5.80579 6.14502 5.96985 6.0083 5.96985 5.81689V4.72314C5.96985 4.55908 5.80579 4.39502 5.64172 4.39502H4.54797C4.35657 4.39502 4.21985 4.55908 4.21985 4.72314ZM5.64172 8.77002C5.80579 8.77002 5.96985 8.6333 5.96985 8.44189V7.34814C5.96985 7.18408 5.80579 7.02002 5.64172 7.02002H4.54797C4.35657 7.02002 4.21985 7.18408 4.21985 7.34814V8.44189C4.21985 8.6333 4.35657 8.77002 4.54797 8.77002H5.64172ZM7.71985 13.145V10.8481C7.71985 10.6841 7.55579 10.52 7.39172 10.52H6.29797C6.10657 10.52 5.96985 10.6841 5.96985 10.8481V13.145H7.71985ZM9.46985 8.44189V7.34814C9.46985 7.18408 9.30579 7.02002 9.14172 7.02002H8.04797C7.85657 7.02002 7.71985 7.18408 7.71985 7.34814V8.44189C7.71985 8.6333 7.85657 8.77002 8.04797 8.77002H9.14172C9.30579 8.77002 9.46985 8.6333 9.46985 8.44189ZM9.46985 5.81689V4.72314C9.46985 4.55908 9.30579 4.39502 9.14172 4.39502H8.04797C7.85657 4.39502 7.71985 4.55908 7.71985 4.72314V5.81689C7.71985 6.0083 7.85657 6.14502 8.04797 6.14502H9.14172C9.30579 6.14502 9.46985 6.0083 9.46985 5.81689ZM9.46985 3.19189V2.09814C9.46985 1.93408 9.30579 1.77002 9.14172 1.77002H8.04797C7.85657 1.77002 7.71985 1.93408 7.71985 2.09814V3.19189C7.71985 3.3833 7.85657 3.52002 8.04797 3.52002H9.14172C9.30579 3.52002 9.46985 3.3833 9.46985 3.19189Z" fill="#AFAFAF"/>
        </svg>
    )
}

const CardIcon5 = () => {
    return (
        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5897 6.42969C14.1093 6.94922 14.1093 7.79688 13.5897 8.28906L8.0116 13.8672C7.51941 14.3867 6.67175 14.3867 6.15222 13.8672L0.38269 8.09766C0.136597 7.85156 -0.00012207 7.52344 -0.00012207 7.16797V1.5625C-0.00012207 0.851562 0.574097 0.25 1.31238 0.25H6.8905C7.24597 0.25 7.5741 0.414062 7.82019 0.660156L13.5897 6.42969ZM3.06238 4.625C3.77332 4.625 4.37488 4.05078 4.37488 3.3125C4.37488 2.60156 3.77332 2 3.06238 2C2.3241 2 1.74988 2.60156 1.74988 3.3125C1.74988 4.05078 2.3241 4.625 3.06238 4.625ZM17.0897 8.28906L11.5116 13.8672C11.0194 14.3867 10.1718 14.3867 9.65222 13.8672L14.41 9.10938C14.8749 8.64453 15.1483 8.01562 15.1483 7.35938C15.1483 6.70312 14.8749 6.10156 14.41 5.63672L9.05066 0.25H10.3905C10.746 0.25 11.0741 0.414062 11.3202 0.660156L17.0897 6.42969C17.6093 6.94922 17.6093 7.79688 17.0897 8.28906Z" fill="#AFAFAF"/>
        </svg>
    )
}

const CardIcon6 = () => {
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99988 0.96875C10.746 0.96875 13.7811 4.00391 13.7811 7.75C13.7811 11.4961 10.746 14.5312 6.99988 14.5312C3.25378 14.5312 0.218628 11.4961 0.218628 7.75C0.218628 4.00391 3.25378 0.96875 6.99988 0.96875ZM9.5155 9.52734C9.57019 9.47266 9.62488 9.36328 9.62488 9.25391C9.62488 9.11719 9.54285 9.00781 9.46082 8.92578L7.87488 7.75V3.8125C7.87488 3.59375 7.65613 3.375 7.43738 3.375H6.56238C6.31628 3.375 6.12488 3.59375 6.12488 3.8125V8.07812C6.12488 8.43359 6.2616 8.73438 6.53503 8.92578L8.36707 10.293C8.42175 10.3477 8.53113 10.4023 8.61316 10.4023C8.77722 10.4023 8.8866 10.3203 8.96863 10.2109L9.5155 9.52734Z" fill="#AFAFAF"/>
        </svg>
    )
}

const CardIcon7 = () => {
    return (
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.73035 11.2812L1.19128 6.74219C0.917847 6.46875 0.917847 6.00391 1.19128 5.73047L2.17566 4.74609C2.4491 4.47266 2.8866 4.47266 3.16003 4.74609L6.24988 7.80859L12.8124 1.24609C13.0858 0.972656 13.5233 0.972656 13.7968 1.24609L14.7811 2.23047C15.0546 2.50391 15.0546 2.96875 14.7811 3.24219L6.74207 11.2812C6.46863 11.5547 6.00378 11.5547 5.73035 11.2812Z" fill="#AFAFAF"/>
        </svg>
    )
}

const CardIcon8 = () => {
    return (
        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5897 6.42969C14.1093 6.94922 14.1093 7.79688 13.5897 8.28906L8.0116 13.8672C7.51941 14.3867 6.67175 14.3867 6.15222 13.8672L0.38269 8.09766C0.136597 7.85156 -0.00012207 7.52344 -0.00012207 7.16797V1.5625C-0.00012207 0.851562 0.574097 0.25 1.31238 0.25H6.8905C7.24597 0.25 7.5741 0.414062 7.82019 0.660156L13.5897 6.42969ZM3.06238 4.625C3.77332 4.625 4.37488 4.05078 4.37488 3.3125C4.37488 2.60156 3.77332 2 3.06238 2C2.3241 2 1.74988 2.60156 1.74988 3.3125C1.74988 4.05078 2.3241 4.625 3.06238 4.625ZM17.0897 8.28906L11.5116 13.8672C11.0194 14.3867 10.1718 14.3867 9.65222 13.8672L14.41 9.10938C14.8749 8.64453 15.1483 8.01562 15.1483 7.35938C15.1483 6.70312 14.8749 6.10156 14.41 5.63672L9.05066 0.25H10.3905C10.746 0.25 11.0741 0.414062 11.3202 0.660156L17.0897 6.42969C17.6093 6.94922 17.6093 7.79688 17.0897 8.28906Z" fill="#AFAFAF"/>
        </svg>
    )
}

const CardIcon9 = () => {
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99988 0.96875C10.746 0.96875 13.7811 4.00391 13.7811 7.75C13.7811 11.4961 10.746 14.5312 6.99988 14.5312C3.25378 14.5312 0.218628 11.4961 0.218628 7.75C0.218628 4.00391 3.25378 0.96875 6.99988 0.96875ZM9.5155 9.52734C9.57019 9.47266 9.62488 9.36328 9.62488 9.25391C9.62488 9.11719 9.54285 9.00781 9.46082 8.92578L7.87488 7.75V3.8125C7.87488 3.59375 7.65613 3.375 7.43738 3.375H6.56238C6.31628 3.375 6.12488 3.59375 6.12488 3.8125V8.07812C6.12488 8.43359 6.2616 8.73438 6.53503 8.92578L8.36707 10.293C8.42175 10.3477 8.53113 10.4023 8.61316 10.4023C8.77722 10.4023 8.8866 10.3203 8.96863 10.2109L9.5155 9.52734Z" fill="#AFAFAF"/>
        </svg>
    )
}

const CardIcon10 = () => {
    return (
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.73035 11.2812L1.19128 6.74219C0.917847 6.46875 0.917847 6.00391 1.19128 5.73047L2.17566 4.74609C2.4491 4.47266 2.8866 4.47266 3.16003 4.74609L6.24988 7.80859L12.8124 1.24609C13.0858 0.972656 13.5233 0.972656 13.7968 1.24609L14.7811 2.23047C15.0546 2.50391 15.0546 2.96875 14.7811 3.24219L6.74207 11.2812C6.46863 11.5547 6.00378 11.5547 5.73035 11.2812Z" fill="#AFAFAF"/>
        </svg>
    )
}

const ExploreCardIcon1 = () => {
    return (
        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.66 8.31055C5.44125 8.31055 3.66 6.5293 3.66 4.31055C3.66 2.12305 5.44125 0.310547 7.66 0.310547C9.8475 0.310547 11.66 2.12305 11.66 4.31055C11.66 6.5293 9.8475 8.31055 7.66 8.31055ZM10.4413 9.31055C12.7538 9.31055 14.66 11.2168 14.66 13.5293V14.8105C14.66 15.6543 13.9725 16.3105 13.16 16.3105H2.16C1.31625 16.3105 0.660004 15.6543 0.660004 14.8105V13.5293C0.660004 11.2168 2.535 9.31055 4.8475 9.31055H5.37875C6.06625 9.6543 6.8475 9.81055 7.66 9.81055C8.4725 9.81055 9.2225 9.6543 9.91 9.31055H10.4413Z" fill="#AFAFAF"/>
        </svg>
    )
}

const ExploreCardIcon2 = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3438 5.61035H5.81252C6.28127 2.8291 7.34377 0.860352 8.59377 0.860352C9.81252 0.860352 10.9063 2.8291 11.3438 5.61035ZM5.59377 8.61035C5.59377 7.92285 5.62502 7.2666 5.68752 6.61035H11.4688C11.5313 7.2666 11.5625 7.92285 11.5625 8.61035C11.5625 9.3291 11.5313 9.98535 11.4688 10.6104H5.68752C5.62502 9.98535 5.59377 9.3291 5.59377 8.61035ZM15.7188 5.61035H12.3438C12.0625 3.86035 11.5625 2.2666 10.7813 1.2041C13.0313 1.86035 14.8438 3.5166 15.7188 5.61035ZM6.37502 1.2041C5.59377 2.2666 5.09377 3.86035 4.81252 5.61035H1.43752C2.31252 3.5166 4.12502 1.86035 6.37502 1.2041ZM16.0625 6.61035C16.2188 7.2666 16.3125 7.92285 16.3125 8.61035C16.3125 9.3291 16.2188 9.98535 16.0625 10.6104H12.4688C12.5313 9.9541 12.5938 9.29785 12.5938 8.61035C12.5938 7.9541 12.5313 7.2666 12.4688 6.61035H16.0625ZM4.59377 8.61035C4.59377 9.29785 4.62502 9.9541 4.68752 10.6104H1.09377C0.937525 9.98535 0.843775 9.3291 0.843775 8.61035C0.843775 7.92285 0.937525 7.2666 1.09377 6.61035H4.68752C4.62502 7.2666 4.59377 7.9541 4.59377 8.61035ZM5.81252 11.6104H11.3438C10.9063 14.4229 9.81252 16.3604 8.59377 16.3604C7.34377 16.3604 6.28127 14.4229 5.81252 11.6104ZM10.7813 16.0479C11.5625 14.9854 12.0938 13.3916 12.3438 11.6104H15.7188C14.8438 13.7354 13.0313 15.3916 10.7813 16.0479ZM1.43752 11.6104H4.81252C5.09377 13.3916 5.59377 14.9854 6.37502 16.0479C4.12502 15.3916 2.31252 13.7354 1.43752 11.6104Z" fill="#AFAFAF"/>
        </svg>
    )
}

const ExploreCardIcon3 = () => {
    return (
        <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.7263 4.2666C9.7888 4.2041 9.88255 4.17285 9.9763 4.17285C10.0388 4.17285 10.1325 4.2041 10.195 4.2666L15.9763 8.98535V14.1104C15.9763 14.3916 15.7263 14.6104 15.4763 14.6104H11.945C11.695 14.6104 11.445 14.3916 11.445 14.1104V11.1104C11.445 10.8604 11.2263 10.6104 10.945 10.6104H8.94505C8.69505 10.6104 8.44505 10.8604 8.44505 11.1104V14.1104C8.44505 14.3916 8.2263 14.6104 7.9763 14.6104H4.4763C4.19505 14.6104 3.9763 14.3916 3.9763 14.1104V9.0166L9.7263 4.2666ZM18.82 7.48535C18.9138 7.54785 18.9763 7.67285 18.9763 7.7666C18.9763 7.86035 18.945 7.9541 18.8825 8.0166L18.07 8.98535C18.0075 9.0791 17.9138 9.11035 17.7888 9.11035C17.695 9.11035 17.6013 9.0791 17.5388 9.0166L10.195 2.98535C10.1325 2.92285 10.0388 2.8916 9.9763 2.8916C9.88255 2.8916 9.7888 2.92285 9.7263 2.98535L2.38255 9.0166C2.32005 9.0791 2.2263 9.11035 2.13255 9.11035C2.00755 9.11035 1.9138 9.0791 1.8513 8.98535L1.0388 8.0166C1.00755 7.9541 0.945045 7.86035 0.945045 7.7666C0.945045 7.67285 1.00755 7.54785 1.1013 7.48535L9.00755 0.954102C9.25755 0.766602 9.6013 0.641602 9.9763 0.641602C10.32 0.641602 10.6638 0.766602 10.9138 0.954102L13.7263 3.2666V1.0166C13.7263 0.797852 13.8825 0.641602 14.1013 0.641602H15.8513C16.0388 0.641602 16.2263 0.797852 16.2263 1.0166V5.3291L18.82 7.48535Z" fill="#AFAFAF"/>
        </svg>
    )
}

const ArrowIcon = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.85532 0.892578L6.45688 0.291016C6.73032 0.0449219 7.14047 0.0449219 7.38657 0.291016L12.7186 5.5957C12.9647 5.86914 12.9647 6.2793 12.7186 6.52539L7.38657 11.8574C7.14047 12.1035 6.73032 12.1035 6.45688 11.8574L5.85532 11.2559C5.60922 10.9824 5.60922 10.5723 5.85532 10.2988L9.16391 7.1543H1.31625C0.933441 7.1543 0.660004 6.88086 0.660004 6.49805V5.62305C0.660004 5.26758 0.933441 4.9668 1.31625 4.9668H9.16391L5.85532 1.84961C5.60922 1.57617 5.58188 1.16602 5.85532 0.892578Z" fill="#22B3C1"/>
            <a href="#contact">
                <rect fill="black" fillOpacity="0" y="-1.05" width="12.25" height="16.1"/>
            </a>
        </svg>
    )
}

export {
    TelegramIcon,
    InstagramIcon,
    UserIcon,
    AreaIcon,
    HomeIcon,
    PhoneIcon,
    MailIcon,
    LocationIcon,
    CardIcon1,
    CardIcon2,
    CardIcon3,
    CardIcon4,
    CardIcon5,
    CardIcon6,
    CardIcon7,
    CardIcon8,
    CardIcon9,
    CardIcon10,
    ExploreCardIcon1,
    ExploreCardIcon2,
    ExploreCardIcon3,
    ArrowIcon,
}